import { useMerchantId } from '@/composables'
import axios from '@axios'

// --- Policy ---

const fetchPolicies = queryParams => axios.get(`/admin/merchants/${useMerchantId()}/policies`, { params: queryParams })

const storePolicy = data => axios.post(`/admin/merchants/${useMerchantId()}/policies`, data)

const updatePolicy = (id, data) => axios.put(`/admin/merchants/${useMerchantId()}/policies/${id}`, data)

const destroyPolicy = id => axios.delete(`/admin/merchants/${useMerchantId()}/policies/${id}`)

export { destroyPolicy, fetchPolicies, storePolicy, updatePolicy }
