<template>
  <v-navigation-drawer
    :value="active"
    temporary
    permanent
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 580 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <v-container>
      <a @click="$emit('aside', false)">Back</a>
    </v-container>

    <policy-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors, useMerchantId } from '@/composables'
import { storePolicy, updatePolicy } from '@api/merchant/policy'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import PolicyForm from './PolicyForm.vue'

export default {
  components: { PolicyForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = { ...form, merchant_id: useMerchantId() }
      const request = props.resource ? updatePolicy(props.resource.id, data) : storePolicy(data)
      await request
        .then(res => {
          emit(props.resource ? 'updated' : 'created', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
