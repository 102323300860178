<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <policy-aside
        v-if="isAsideActive && aside === 'merchant_policy'"
        v-model="isAsideActive"
        @changed="addedPolicy"
      ></policy-aside>

      <order-remark-aside
        v-if="isAsideActive && aside === 'merchant_remarks'"
        v-model="isAsideActive"
        @changed="addedRemark"
      ></order-remark-aside>

      <brand-aside
        v-if="isAsideActive && aside === 'brand'"
        v-model="isAsideActive"
        @changed="addedBrand"
      ></brand-aside>

      <v-card-text>
        <v-row class="mt-5">
          <v-col :cols="cols">
            <v-divider class="pb-5" />

            <h4 class="pb-2">
              Merchant related
            </h4>

            <v-autocomplete
              v-model="form.merchant_id"
              placeholder="Merchant"
              :items="merchantOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
              :rules="[between(form.merchant_id, 1, 999999999)]"
              @change="merchantChange"
            ></v-autocomplete>

            <v-text-field
              v-model="form.merchant_product_id"
              label="(Optional) Merchant Custom product ID"
              hint="Merchant's custom product ID, usually associated with internal number."
            ></v-text-field>

            <div class="d-flex">
              <v-autocomplete
                v-model="form.brand_id"
                label="Product Brand"
                :items="brandOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                :rules="[between(form.brand_id, 1, 999999999)]"
              ></v-autocomplete>
              <a
                class="ml-5"
                @click="setAside('brand')"
              >+new</a>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols*2">
            <v-divider class="pb-5" />
            <h4>Product Information</h4>

            <v-text-field
              v-model="form.nature"
              label="Product Nature"
              disabled
            ></v-text-field>

            <translatable-input
              v-model="form.title"
              label="Product title"
              class="mt-5"
            />

            <v-text-field
              v-model="form.slug"
              label="Product Slug"
              :rules="[required]"
              :hint="`https://portal.mixcarehealth.com/products/${form.slug}`"
              @change="handleSlug"
            >
              <template v-slot:append>
                <a @click="autoSlug">auto</a>
              </template>
            </v-text-field>

            <translatable-editor
              v-model="form.content"
              label="Product rich content (in description section)"
              class="mt-5 content-editors"
              :eidtor-options="{ uploadUrl, postUuid: form.post_uuid }"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="cols">
            <v-divider class="pb-5" />

            <h4 class="pb-2">
              Purchase related
            </h4>

            <v-text-field
              v-model="form.min_order_quantity"
              label="Min Order Quantity"
              suffix="pcs/ppl"
              :rules="[between(form.min_order_quantity, 1, 100)]"
              hint="The customer have to buy min X quantity"
            ></v-text-field>

            <v-text-field
              v-model="form.max_order_quantity"
              label="Max Order Quantity"
              class="mb-4"
              suffix="pcs/ppl"
              :rules="[between(form.max_order_quantity, 1, 9999999)]"
              hint="The customer can buy max X quantity. If all its SKUs quantity sumup to a larger number, cart and checkout fails. If package, max should be at least the number of max selectable SKUs in a purchase."
            ></v-text-field>

            <v-text-field
              v-model="form.max_lifetime_quantity"
              label="Max Lifetime Quantity"
              class="mb-4"
              suffix="pcs/ppl"
              :rules="[between(form.max_lifetime_quantity, 0, 9999999)]"
              hint="Each User account can buy max X quantity lifetime. 0=unlimited. Don't use >999."
            ></v-text-field>

            <div class="d-flex">
              <v-autocomplete
                v-model="form.policy_id"
                label="Product Policy"
                :items="policyOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                :rules="[between(form.policy_id, 1, 999999999)]"
              ></v-autocomplete>
              <a
                v-if="form.merchant_id"
                class="ml-5"
                @click="setAside('merchant_policy')"
              >+new</a>
            </div>

            <div class="d-flex">
              <v-autocomplete
                v-model="form.order_remark_id"
                label="(Optional) Order Remarks"
                :items="orderRemarkOptions"
                item-text="title"
                item-value="value"
                outlined
                clearable
                dense
              ></v-autocomplete>
              <a
                v-if="form.merchant_id"
                class="ml-5"
                @click="setAside('merchant_remarks')"
              >+new</a>
            </div>

            <div class="d-flex">
              <v-autocomplete
                v-model="form.participant_rule_id"
                label="Requirements of Participant's info"
                :items="participantRuleOptions"
                item-text="title"
                item-value="value"
                :disabled="form.participant_rule_id && noRule"
                outlined
                dense
              ></v-autocomplete>
            </div>

            <v-divider class="pb-5" />

            <h4 class="pb-2">
              Country-related: Currency, Time zone, Tax
            </h4>

            <p>Expect: User searches this country to see it. Normally, most products should be in the <a @click="form.country_id = baseCountry ? baseCountry.id : form.country_id">base country</a></p>

            <CountryIdFilter
              :key="form.country_id"
              v-model="form"
              class="mr-5"
              :clearable="false"
              :hide-details="false"
              dense
            />

            <div class="ml-5">
              <p>Warning: To support checkout with non-based currency (e.g. CNY), enhancement is required. Otherwise, you should always set to base currency (HKD).</p>
              <CurrencyFilter
                :key="form.currency"
                v-model="form"
                :clearable="false"
                :hide-details="false"
                :disabled="!form.country_id"
                dense
                @changed="onCurrencyChanged"
              />

              <v-autocomplete
                v-model="form.time_zone_id"
                label="Time Zone (Use Product's Local Time)"
                :items="timeZoneOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                :rules="[between(form.time_zone_id, 1, 999)]"
                :disabled="!form.country_id"
              ></v-autocomplete>

              <p v-if="country && country.require_vat_tax && !form.tax_scheme_id">
                Note: Tax scheme is generally required for this country
              </p>

              <v-autocomplete
                v-model="form.tax_scheme_id"
                label="(Optional) Country Tax Scheme"
                :items="taxSchemeOptions"
                item-text="title"
                item-value="value"
                :disabled="!form.country_id"
                outlined
                dense
                clearable
              ></v-autocomplete>
            </div>

            <template v-if="form.nature_info && form.nature_info.service">
              <v-divider class="pb-5" />
              <h4>Extra Info (Referencing Display)</h4>
              <template v-if="form.nature_info.service">
                <v-text-field
                  v-model="form.nature_info.service.min_duration_minute"
                  label="Min. service duration (mins)"
                  :rules="[between(form.nature_info.service.min_duration_minute, 1, 99999)]"
                ></v-text-field>
                <v-text-field
                  v-model="form.nature_info.service.max_duration_minute"
                  label="Max. service duration (mins)"
                  :rules="[between(form.nature_info.service.max_duration_minute, 1, 99999)]"
                ></v-text-field>
                <v-text-field
                  v-model="form.nature_info.service.min_participant"
                  label="Min marticipants (ppl)"
                  :rules="[between(form.nature_info.service.min_participant, 1, 999)]"
                ></v-text-field>
                <v-text-field
                  v-model="form.nature_info.service.max_participant"
                  label="Max marticipants (ppl)"
                  :rules="[between(form.nature_info.service.max_participant, 1, 999)]"
                ></v-text-field>
              </template>
            </template>

            <template>
              <v-divider class="pb-5" />
              <h4>Manifest</h4>

              <v-row :cols="3">
                <v-col class="pb-0">
                  <v-switch
                    v-model="form.manifest.enable_quickview"
                    label="Enable Quickview"
                  />
                </v-col>
                <v-col class="pb-0">
                  <v-switch
                    v-model="form.manifest.show_brand"
                    label="Show Brand"
                  />
                </v-col>
                <v-col class="pb-0">
                  <v-switch
                    v-model="form.manifest.show_merchant"
                    label="Show Merchant"
                  />
                </v-col>
                <v-col
                  :cols="12"
                  class="pt-0 pb-0"
                >
                  <v-switch
                    v-model="form.manifest.show_attribute_selection"
                    label="show the attribute selection (e.g. blue/green)."
                    hide-details
                  />
                  <small>If the attribute selection is meaningful (like blue/green, 100g/200g), set to "true". </small>
                  <small class="d-block mb-5">Otherwise, if selections are like plan1/plan2 which makes no sense in PWC's APP, set to "false".</small>
                </v-col>
              </v-row>
            </template>

            <v-switch
              v-model="form.active"
              color="primary"
              :label="form.active ? 'Active' : 'Inactive'"
              :disabled="disabledFields.indexOf('active') >= 0"
              :messages="[ 'Active after published. It can be purchased by channel\'s users.' ]"
            ></v-switch>

            <v-switch
              v-model="form.visible"
              color="primary"
              :label="form.visible ? 'Visible' : 'Invisible'"
              :disabled="disabledFields.indexOf('visible') >= 0"
              hide-details
            ></v-switch>

            <small>Visible in general listing. To show/list with visible=0, need 1) visit product link directly (knowledge of slug), or 2) list items inside selection, e.g. ?selection_key=product_featured</small>

            <!-- <p class="mt-2">
              E.g. Mark product invisible to all users. And add it to AXA's selection "product_featured". So it can list the invisible products via selection in white label section "home_product_featured".
            </p> -->
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {
  CountryIdFilter,
  CurrencyFilter,
  TranslatableEditor, TranslatableInput, TranslationLocales,
} from '@/components'
import {
  useCountry, useSlugify, useStripTags, useTranslatable,
} from '@/composables'
import appStore from '@/modules/app/store'
import merchantStore from '@/modules/merchant/store'
import OrderRemarkAside from '@/modules/merchant/views/order-remark-resource/OrderRemarkAside.vue'
import PolicyAside from '@/modules/merchant/views/policy-resource/PolicyAside.vue'
import productStore from '@/modules/product/store'
import BrandAside from '@/modules/product/views/product-resource/BrandAside.vue'
import { t } from '@/plugins/i18n'
import store from '@/store'
import { between, required } from '@core/utils/validation'
import { } from '@mdi/js'
import {
  computed,
  ref, watch,
} from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    TranslatableInput,
    TranslatableEditor,
    TranslationLocales,
    CurrencyFilter,
    CountryIdFilter,
    PolicyAside,
    OrderRemarkAside,
    BrandAside,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    nature: {
      type: String,
      default: 'tangible',
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const nature = props.resource ? props.resource.nature : props.nature

    const getNatureInfo = () => {
      if (['service', 'activity'].includes(nature)) {
        return {
          service: {
            min_duration_minute: 1,
            max_duration_minute: 1,
            min_participant: 1,
            max_participant: 1,
          },
        }
      }

      return {
        [nature]: {},
      }
    }

    const noRule = ['tangible', 'plan'].includes(nature)
    const defaultRequired = computed(() => productStore.state.participantRules.find(r => r.mandatory && r.fields.birthday === 'optional')?.id)
    const defaultNotRequired = computed(() => productStore.state.participantRules.find(r => !r.mandatory)?.id)
    const defaultRuleId = noRule ? defaultNotRequired : defaultRequired.value

    const boolOrTrue = item => ([true, false].includes(item) ? item : true)

    const initialForm = {
      active: props.resource ? props.resource.active : true,
      visible: props.resource ? props.resource.visible : true,
      nature,
      nature_info: props.resource ? props.resource.nature_info || getNatureInfo() : getNatureInfo(),
      manifest: {
        enable_quickview: boolOrTrue(props.resource?.manifest?.enable_quickview),
        show_brand: boolOrTrue(props.resource?.manifest?.show_brand),
        show_merchant: boolOrTrue(props.resource?.manifest?.show_merchant),
        show_attribute_selection: boolOrTrue(props.resource?.manifest?.show_attribute_selection),
      },
      slug: props.resource ? props.resource.slug : '',

      title: useTranslatable(props.resource, 'title'),
      content: useTranslatable(props.resource, 'content'),
      policy_id: props.resource ? props.resource.policy_id : null,
      order_remark_id: props.resource ? props.resource.order_remark_id : null,
      participant_rule_id: props.resource ? props.resource.participant_rule_id : defaultRuleId,
      brand_id: props.resource ? props.resource.brand_id : null,
      merchant_id: props.resource ? props.resource.merchant_id : null,
      merchant_product_id: props.resource ? props.resource.merchant_product_id : '',
      post_uuid: props.resource ? props.resource.post_uuid : uuidv4(), // generate this uuid, so can upload before product creation
      min_order_quantity: props.resource ? props.resource.min_order_quantity : 1,
      max_order_quantity: props.resource ? props.resource.max_order_quantity : 99999,
      max_lifetime_quantity: props.resource ? props.resource.max_lifetime_quantity : 0,

      country_id: props.resource ? props.resource.country_id : null,
      tax_scheme_id: props.resource ? props.resource.tax_scheme_id : null,
      currency: props.resource ? props.resource.currency : null,
      time_zone_id: props.resource ? props.resource.time_zone_id : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      console.log('valid?', formElem.value.validate())
      if (formElem.value.validate()) emit('submit', form.value)
    }

    store.dispatch('product/fetchAllBrands')

    const merchantOptions = computed(() => merchantStore.state.all.map(merchant => ({ title: `#${merchant.id} - ${t(merchant.name)}`, value: merchant.id })))

    // @todo note: list only merchant's policy
    const policyOptions = computed(() => merchantStore.state.policies.map(policy => ({ title: `#${policy.id} - ${useStripTags(t(policy.confirmation_text)).substr(0, 100)}`, value: policy.id })))
    const orderRemarkOptions = computed(() => merchantStore.state.orderRemarks.map(rm => ({ title: `#${rm.id} - ${useStripTags(t(rm.content)).substr(0, 100)}`, value: rm.id })))

    const mapRule = rule => {
      if (!rule.mandatory) {
        return { title: 'Not required', value: rule.id }
      }
      const reqs = []
      const opts = []
      for (const attr in rule.fields) {
        if (rule.fields[attr] === 'required') {
          reqs.push(attr)
        }
        if (rule.fields[attr] === 'optional') {
          opts.push(attr)
        }
      }

      return { title: `(Required: ${reqs.length}) ${reqs.join(', ')} | (Optional: ${opts.length}) ${opts.join(', ')}`, value: rule.id }
    }
    const participantRuleOptions = computed(() => productStore.state.participantRules.map(mapRule))

    // @todo note: service has unique brand. list only merchant's brand
    const brandOptions = computed(() => productStore.state.brands.map(brand => ({ title: `#${brand.slug} - ${useStripTags(t(brand.name))}`, value: brand.id })))

    const timeZoneOptions = computed(() => appStore.state.timeZones.map(tz => ({ title: tz.name, value: tz.id })))

    const taxSchemeOptions = computed(() => productStore.state.taxSchemes.filter(ts => ts.country_id === form.value.country_id).map(tz => ({ title: t(tz.name), value: tz.id })))

    // const brandOptions = ref([{ title: 'test Brand #1', value: 1 }])

    const merchantChange = id => {
      const current = merchantStore.state.all.find(m => m.id === id)
      store.dispatch('merchant/setCurrent', current)
    }

    const handleSlug = text => {
      form.value.slug = useSlugify(text || '')
    }

    const autoSlug = () => {
      form.value.slug = useSlugify(form.value.title.en)
    }

    const aside = ref('')
    const isAsideActive = ref(true)
    const setAside = section => {
      aside.value = section
      isAsideActive.value = true
    }

    const addedPolicy = resource => {
      aside.value = ''
      form.value.policy_id = resource.id
      isAsideActive.value = false
      merchantStore.state.policies.push(resource)
      store.dispatch('merchant/fetchPolicies')
    }

    const addedRemark = resource => {
      aside.value = ''
      form.value.order_remark_id = resource.id
      isAsideActive.value = false
      merchantStore.state.orderRemarks.push(resource)
      store.dispatch('merchant/fetchOrderRemarks')
    }

    const addedBrand = resource => {
      aside.value = ''
      form.value.brand_id = resource.id
      isAsideActive.value = false
      productStore.state.brands.push(resource)
      store.dispatch('product/fetchAllBrands')
    }

    const { findCountry, baseCountry } = useCountry()

    const country = computed(() => findCountry(form.value.country_id))

    watch(country, cty => {
      form.value.currency = cty.currency
      form.value.time_zone_id = form.value.time_zone_id || cty.main_timezone.id
      form.value.tax_scheme_id = cty.tax_schemes.find(ts => ts.main)?.id || null
    })

    return {
      form,
      formElem,
      validate,
      between,
      required,
      noRule,

      country,

      merchantOptions,
      policyOptions,
      orderRemarkOptions,
      participantRuleOptions,
      brandOptions,
      timeZoneOptions,
      taxSchemeOptions,

      merchantChange,
      handleSlug,
      autoSlug,

      uploadUrl: 'product',

      aside,
      isAsideActive,
      setAside,
      addedPolicy,
      addedRemark,
      addedBrand,

      baseCountry,

      onCurrencyChanged: data => {
        form.value.currency = data.currency
      },
    }
  },
}
</script>

<style lang="scss">
.content-editors {
  .ck-content {
    height: 500px; // so that it does not overflow the stepper
  }
}
</style>
