import { useMerchantId } from '@/composables'
import axios from '@axios'

// --- OrderRemark ---

const fetchOrderRemarks = queryParams => axios.get(`/admin/merchants/${useMerchantId()}/order-remarks`, { params: queryParams })

const storeOrderRemark = data => axios.post(`/admin/merchants/${useMerchantId()}/order-remarks`, data)

const updateOrderRemark = (id, data) => axios.put(`/admin/merchants/${useMerchantId()}/order-remarks/${id}`, data)

const destroyOrderRemark = id => axios.delete(`/admin/merchants/${useMerchantId()}/order-remarks/${id}`)

export { destroyOrderRemark, fetchOrderRemarks, storeOrderRemark, updateOrderRemark }
