import { useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import store from '@/store'
import {
  destroyPackage,
  destroyPackageMapping,
  destroyProduct,
  destroySku,
  fetchProduct,
  handleProductStatus,
  handleSkuStatus,
  replicateProduct,
} from '@api/product'
import { ref } from '@vue/composition-api'

export default function useProductView() {
  const productData = ref(null)
  const loading = ref(false)

  // fetch data
  const loadProduct = id => {
    productData.value = null
    loading.value = true

    return fetchProduct(id)
      .then(res => {
        productData.value = res.data.data

        return res
      })
      .catch(useNotifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  const trashProduct = id => destroyProduct(id).catch(useNotifyErrors)

  const cloneProduct = id => replicateProduct(id).catch(useNotifyErrors)

  const confirmTrashProduct = product =>
    // console.log('product is', product)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Action',
          content: `Confirm Trash product: <b>${t(product.title)}</b> ?`,
          resolve: () => trashProduct(product.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  const confirmReplicate = product =>
    // console.log('product is', product)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Replicate',
          content: `Confirm Replicate product: <b>${t(product.title)}</b> ?`,
          resolve: () => cloneProduct(product.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  const isPublishable = product => product.status !== 'published'

  const isUnpublishable = product => product.status === 'published'

  const publishProduct = id => handleProductStatus(id, 'publish').catch(useNotifyErrors)

  const unpublishProduct = id => handleProductStatus(id, 'unpublish').catch(useNotifyErrors)

  const publishSku = id => handleSkuStatus(id, 'publish').catch(useNotifyErrors)

  const unpublishSku = id => handleSkuStatus(id, 'unpublish').catch(useNotifyErrors)

  const trashSku = id => destroySku(id).catch(useNotifyErrors)

  const confirmTrashSku = sku =>
    // console.log('sku is', sku)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Action',
          content: `Confirm Trash sku: <b>${t(sku.name)}</b> ?`,
          resolve: () => trashSku(sku.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  const trashPackage = id => destroyPackage(id).catch(useNotifyErrors)

  const confirmTrashPackage = pkg =>
    // console.log('sku is', sku)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Action',
          content: `Confirm Trash Package: <b>${t(pkg.name)}</b> ?`,
          resolve: () => trashPackage(pkg.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  const trashPackageMapping = mappingId => destroyPackageMapping(mappingId).catch(useNotifyErrors)

  const confirmTrashPackageMapping = mapping =>
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Action',
          content: `Confirm Trash Item: <b>${t(mapping.sku.name)}</b> ?`,
          resolve: () => trashPackageMapping(mapping.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  return {
    loading,

    productData,

    loadProduct,
    trashProduct,
    confirmTrashProduct,
    confirmReplicate,
    isPublishable,
    isUnpublishable,
    publishProduct,
    unpublishProduct,
    publishSku,
    unpublishSku,
    confirmTrashSku,
    confirmTrashPackage,
    confirmTrashPackageMapping,
  }
}
